/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { ScrollingProvider } from 'react-scroll-section';
import config from 'react-reveal/globals';
import { StaticQuery, graphql } from 'gatsby';
import { IntlProvider } from 'react-intl';
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';
import 'intl';

import colors from '../../colors';
import Helmet from './Helmet';
import Header from './Header';
import Footer from './Footer';

const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: inherit;
    }

  body {
    box-sizing: border-box;
    margin: 0;
    font-family: Cabin, 'Open Sans', sans-serif;
    font-display: swap;
    font-display: fallback;
    overflow-x: hidden;
  }
`;

config({ ssrFadeout: true });

const loadScript = src => {
  const tag = document.createElement('script');
  tag.src = src;
  tag.defer = true;

  document.getElementsByTagName('body')[0].appendChild(tag);
};

const Layout = ({ children, location, i18nMessages }) => {
  useEffect(() => {
    loadScript('https://use.fontawesome.com/fd58d214b9.js');
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              languages {
                defaultLangKey
                langs
              }
            }
          }
        }
      `}
      render={data => {
        const url = location.pathname || '';
        const { langs, defaultLangKey } = data.site.siteMetadata.languages;
        const langKey = getCurrentLangKey(langs, defaultLangKey, url);
        const homeLink = `/${langKey}`.replace(`/${defaultLangKey}/`, '/');
        const langsMenu = getLangs(
          langs,
          langKey,
          getUrlForLang(homeLink, url),
        ).map(item => ({
          ...item,
          link: item.link.replace(`/${defaultLangKey}/`, '/'),
        }));
        return (
          <IntlProvider locale={langKey} messages={i18nMessages}>
            <main>
              <GlobalStyle />
              <ThemeProvider theme={{ colors }}>
                <ScrollingProvider>
                  <Helmet />
                  <Header langs={langsMenu} />
                  {children}
                  <Footer />
                </ScrollingProvider>
              </ThemeProvider>
            </main>
          </IntlProvider>
        );
      }}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  location: {},
};

export default Layout;
